import { faCarSide } from '@fortawesome/pro-regular-svg-icons/faCarSide';
import { faLocationArrow } from '@fortawesome/pro-regular-svg-icons/faLocationArrow';
import { faPencilAlt } from '@fortawesome/pro-regular-svg-icons/faPencilAlt';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import useEventListener from '@propertypal/shared/src/hooks/useEventListener';
import { RecentSearch } from '@propertypal/shared/src/hooks/useRecentSearches';
import { SearchFormNlpResult, Token } from '@propertypal/shared/src/types/search';
import { Filters } from '@propertypal/shared/src/utils/search/getNlpFilters';
import groupTokensByType, { Suggestion } from '@propertypal/shared/src/utils/search/groupTokensByType';
import React, { FunctionComponent, KeyboardEventHandler, ReactEventHandler, useEffect, useRef, useState } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { useTheme } from 'styled-components';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import { Text } from '../typography';
import {
  Container,
  Input,
  SuggestionList,
  Header,
  Item,
  ItemImage,
  LoadingBox,
  SearchInputIcon,
} from './SearchInput.style';

const DEFAULT_PLACEHOLDER = 'Search Area, Address, Agent';

interface Props {
  containerStyle?: React.CSSProperties;
  disableSavedSearches?: boolean;
  onChangeText: (text: string) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
  loading?: boolean;
  tokens?: Token[];
  value: string;
  nlpData?: SearchFormNlpResult;
  onSuggestionClick?: (token: Suggestion) => void;
  onLocationClick?: (position: 'inline' | 'suggestion') => void;
  onTravelTimeClick?: (position: 'inline' | 'suggestion') => void;
  onDrawSearchClick?: (position: 'inline' | 'suggestion') => void;
  suggestionsOffset?: number;
  suggestionsLeftOffset?: number;
  isHomePage?: boolean;
  placeholder?: string;
  recentSearches?: RecentSearch[];
  onRecentSearchClick?: (searchText: string, filters: Filters) => void;
  onRemoveRecentSearch?: (search: RecentSearch) => void;
}

const SUGGESTIONS_ID = 'search-suggestions';

const SearchInput: FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const [suggestion, setSuggestion] = useState(0);
  const [focused, setFocused] = useState(false);
  const sections = props.nlpData ? groupTokensByType(props.nlpData, props.disableSavedSearches) : [];
  const lastClick = useRef<number>(0);

  const handleClick: ReactEventHandler = (e) => {
    const now = new Date().getTime();

    if (props.tokens && now - lastClick.current > 500) {
      const input = e.target as HTMLInputElement;

      const newToken = props.tokens.find((token) => {
        return (
          input.selectionStart != null && token.start <= input.selectionStart && token.trimmedEnd > input.selectionStart
        );
      });

      if (newToken) {
        input.setSelectionRange(newToken.start, newToken.trimmedEnd);
      }
    }

    lastClick.current = now;
  };

  const scrollToIndex = (index: number) => {
    const element = document.getElementById(`search-suggestion-${index}`);
    const scrollElement = document.getElementById(SUGGESTIONS_ID);
    if (element && scrollElement) {
      scrollElement.scrollTo(0, element.offsetTop - 200);
    }
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setSuggestion(suggestion + 1);
      scrollToIndex(suggestion + 1);
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setSuggestion(Math.max(0, suggestion - 1));
      scrollToIndex(Math.max(0, suggestion - 1));
    } else if (event.key === 'Enter') {
      let token: Suggestion | null = null;

      sections.forEach((section) => {
        section.data.forEach((mToken) => {
          if (mToken.canonicalIndex === suggestion) {
            token = mToken;
          }
        });
      });

      if (token && props.onSuggestionClick) {
        event.preventDefault();
        props.onSuggestionClick(token);
        setFocused(false);
      }
    }
  };

  const handleKeyEvent = (e: KeyboardEvent) => {
    const el = document.querySelector<HTMLInputElement>('[data-testid="searchInput"]');

    if (el && e.code === 'Slash' && document.activeElement !== el) {
      e.preventDefault();
      el.focus();
    }
  };

  const handleClickEvent = (e: MouseEvent) => {
    // @ts-ignore
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setFocused(false);
    }
  };

  useEventListener('click', handleClickEvent);
  useEventListener('keydown', handleKeyEvent);

  useEffect(() => {
    setSuggestion(0);
  }, [props.nlpData]);

  return (
    <Container ref={containerRef} style={props.containerStyle}>
      <Input
        aria-label="Property search text"
        ref={props.inputRef}
        onClick={handleClick}
        onChange={(event) => props.onChangeText(event.target.value)}
        onKeyDown={handleKeyDown}
        data-testid="searchInput"
        placeholder={props.placeholder || DEFAULT_PLACEHOLDER}
        onFocus={() => setFocused(true)}
        value={props.value}
        aria-autocomplete="both"
        aria-owns={SUGGESTIONS_ID}
        aria-activedescendant={`search-suggestion-${suggestion}`}
        type="search"
      />

      {!props.loading && props.onDrawSearchClick && (
        <SearchInputIcon
          aria-label="Draw a search"
          title="Draw a search"
          onClick={() => props.onDrawSearchClick && props.onDrawSearchClick('inline')}
          type="button"
        >
          <FontAwesomeIcon icon={faPencilAlt} size={22} style={{ marginTop: 2 }} />
        </SearchInputIcon>
      )}

      {!props.loading && props.onTravelTimeClick && (
        <SearchInputIcon
          aria-label="Search by travel time"
          title="Search by travel time"
          onClick={() => props.onTravelTimeClick && props.onTravelTimeClick('inline')}
          type="button"
          data-testid="travelTimeButton"
        >
          <FontAwesomeIcon icon={faCarSide} size={22} style={{ marginTop: 2 }} />
        </SearchInputIcon>
      )}

      {!props.loading && props.onLocationClick && (
        <SearchInputIcon
          aria-label="Search by current location"
          title="Search by current location"
          onClick={() => props.onLocationClick && props.onLocationClick('inline')}
          type="button"
        >
          <FontAwesomeIcon icon={faLocationArrow} size={22} style={{ marginTop: 2 }} />
        </SearchInputIcon>
      )}

      {props.loading && (
        <LoadingBox>
          <ScaleLoader color={theme.backgroundLight} data-testid="inputLoading" height={25} />
        </LoadingBox>
      )}

      {focused && (
        <SuggestionList
          id={SUGGESTIONS_ID}
          role="listbox"
          offset={props.suggestionsOffset}
          leftOffset={props.suggestionsLeftOffset}
          data-testid="suggestionList"
          isHomePage={props.isHomePage}
        >
          {!sections.length && (
            <>
              <Header>Suggestions</Header>

              {props.onDrawSearchClick && (
                <Item
                  onClick={() => {
                    if (props.onDrawSearchClick) {
                      props.onDrawSearchClick('suggestion');
                      setFocused(false);
                    }
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPencilAlt}
                    color={theme.textDark}
                    style={{ fontSize: 20, marginRight: 15 }}
                  />

                  <Text>Draw a Search</Text>
                </Item>
              )}

              {props.onTravelTimeClick && (
                <Item
                  onClick={() => {
                    if (props.onTravelTimeClick) {
                      props.onTravelTimeClick('suggestion');
                      setFocused(false);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faCarSide} color={theme.textDark} style={{ fontSize: 20, marginRight: 15 }} />

                  <Text>Search By Travel Time</Text>
                </Item>
              )}

              <Item
                data-testid="locationButton"
                onClick={() => {
                  if (props.onLocationClick) {
                    props.onLocationClick('suggestion');
                    setFocused(false);
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={faLocationArrow}
                  color={theme.textDark}
                  style={{ fontSize: 20, marginRight: 15 }}
                />

                <Text>Search by Current Location</Text>
              </Item>
            </>
          )}

          {!!sections.length &&
            sections.map((section) => (
              <React.Fragment key={section.key}>
                <Header>{section.key}</Header>

                {section.data.map((token) => (
                  <Item
                    id={`search-suggestion-${token.canonicalIndex}`}
                    focused={token.canonicalIndex === suggestion}
                    key={token.id}
                    data-testid={`suggestion${token.id}`}
                    onClick={() => {
                      if (props.onSuggestionClick) {
                        props.onSuggestionClick(token);
                        setFocused(false);
                      }
                    }}
                  >
                    {!!token.image && <ItemImage src={token.image} data-testid={`suggestion${token.id}Image`} />}

                    <div>
                      {!!token.heading && (
                        <Text fontSize={18} fontWeight="bold" align="left" data-testid={`suggestion${token.id}Heading`}>
                          {token.heading}
                        </Text>
                      )}

                      <Text align="left" data-testid={`suggestion${token.id}Text`}>
                        {token.text}
                      </Text>

                      {!!token.subText && (
                        <Text align="left" data-testid={`suggestion${token.id}SubText`}>
                          {token.subText}
                        </Text>
                      )}
                    </div>
                  </Item>
                ))}
              </React.Fragment>
            ))}

          {!!props.recentSearches?.length && (
            <>
              <Header>Recent Searches</Header>

              {props.recentSearches.map((search) => (
                <Item
                  key={search.description.complete}
                  onClick={() => {
                    if (props.onRecentSearchClick) {
                      props.onRecentSearchClick(search.text, search.filters);
                      setFocused(false);
                    }
                  }}
                >
                  <div>
                    <Text color={theme.textDark}>{search.description.stem}</Text>

                    {!!search.description.details && (
                      <Text fontSize={14} mt={4} color={theme.textLight}>
                        {search.description.details}
                      </Text>
                    )}
                  </div>

                  <button
                    type="button"
                    onClick={(e) => {
                      if (props.onRemoveRecentSearch) {
                        e.stopPropagation();
                        props.onRemoveRecentSearch(search);
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} size={18} color={theme.textDark} />
                  </button>
                </Item>
              ))}
            </>
          )}
        </SuggestionList>
      )}
    </Container>
  );
};

export default SearchInput;
